<template>
    <div class="controllerJieshao">
        <div class="controller-modules-header left">术前</div>
        <div class="dsc" v-html="caseInfo.operationBefore"></div>
       
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["caseInfo",]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>